import OrderNavbar from 'components/MyOrder/navbar';
import MyOrderInfo from 'components/MyOrder/info';
import PayPop from "components/Subscribe/PayPop";
import ClPop from "components/ClPop";
import { ref } from 'vue';
export default {
  components: {
    OrderNavbar: OrderNavbar,
    MyOrderInfo: MyOrderInfo,
    PayPop: PayPop,
    ClPop: ClPop
  },
  setup: function setup() {
    var status = ref(0);

    var tabClick = function tabClick(index) {
      status.value = index;
    };

    return {
      tabClick: tabClick,
      status: status
    };
  }
};