import { nextTick } from "process";
import { onMounted, ref } from "vue";
document.body.style.background = '#f6f6f6';
export default {
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var list = ['全部订单', '未支付', '未使用', '已使用', '已取消'];
    var currentIndex = ref(0);
    var el = ref(null);

    var changeTab = function changeTab(e, i) {
      console.log(e);
      currentIndex.value = i;
      emit('tabClick', i);
    };

    return {
      list: list,
      currentIndex: currentIndex,
      el: el,
      changeTab: changeTab
    };
  }
};