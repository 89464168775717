import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0626be05"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "navbar pointer"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  class: "shape"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(['item', {
        select: $setup.currentIndex === index
      }]),
      onClick: function onClick($event) {
        return $setup.changeTab($event, index);
      }
    }, [_createElementVNode("span", null, _toDisplayString(item), 1), _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [[_vShow, $setup.currentIndex === index]])], 10, _hoisted_2);
  }), 256))]);
}